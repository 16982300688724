import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const useMyPage = () => {
  const ip = process.env.REACT_APP_URL
  const [profile, setProfile] = useState([])
  const [profileImage] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [categorySubList, setCategorySubList] = useState([])
  const [activeIndex, setActiveIndex] = useState([])
  const [activeSubIndex, setActiveSubIndex] = useState([])
  const fileInputRef = useRef(null)

  const navigate = useNavigate()

  const modify = () => {
    navigate('/ModifyMyPage')
  }

  useEffect(() => {
    handleCategory()
    handleProfile()
  }, [])

  const handleProfile = async () => {
    try {
      await axios
        .get(`${ip}/channel`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setProfile(response.data)
          setActiveIndex(response.data.channelCategories)
          setActiveSubIndex(response.data.channelDetailCategories)
          handleCategorySub(response.data.channelCategories)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategory = async () => {
    try {
      await axios
        .get(`${ip}/category`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setCategoryList(response.data.list)
          handleCategorySub(response.data.list[0].name)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategorySub = async (name) => {
    try {
      await axios
        .get(`${ip}/category/sub`, {
          params: { name: name },
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setCategorySubList(response.data.list)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleButtonClick = (index, name) => {
    setActiveIndex(index)
    setActiveSubIndex(0)
    handleCategorySub(name)
  }

  const handleSubButtonClick = (index) => {
    setActiveSubIndex(index)
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {}
      reader.readAsDataURL(file)
    }
  }

  return {
    modify,
    handleProfile,
    handleCategorySub,
    handleCategory,
    handleButtonClick,
    handleSubButtonClick,
    handleImageUpload,
    profile,
    profileImage,
    categoryList,
    categorySubList,
    activeIndex,
    activeSubIndex,
    fileInputRef,
  }
}

export default useMyPage
