import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import OutLineSideBar from '../../newComponent/outline/OutLineSideBar'
import EpisodeCreateForm_v1 from '../../newComponent/create/EpisodeCreateForm_v1'
import CardComponent from '../../newComponent/mypage/CardComponent'

const NewEpisodeCreate_v1 = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage = 'Are you sure you want to leave this page?'
      e.returnValue = confirmationMessage // 이 메시지는 일부 브라우저에서만 표시됩니다.
      return confirmationMessage
    }

    const handlePopState = (e) => {
      if (!window.confirm('Are you sure you want to leave this page?')) {
        navigate(location.pathname) // 현재 경로로 다시 이동
        e.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [navigate, location])

  return (
    <div className="flex h-screen bg-blue-700">
      <OutLineSideBar />
      <main className="flex-grow flex justify-center items-center p-5 ml-[-5%]">
        <div
          className="bg-[#F7F8FC] p-5 rounded-2xl flex h-[90%] relative shadow-md overflow-y-auto"
          style={{ flexBasis: '90%' }}
        >
          <div className="basis-2/3 h-full overflow-y-auto">
            <EpisodeCreateForm_v1 />
          </div>
          <div className="w-[2px] bg-gray-300 mx-5"></div>
          <div className="basis-1/3 flex flex-col">
            <CardComponent />
          </div>
        </div>
      </main>
    </div>
  )
}

export default NewEpisodeCreate_v1
