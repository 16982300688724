import React from 'react'
import { GooglePod, ApplePod, Spotify, LinkIBlueIcon } from '../../asset/icon'
import { useNavigate } from 'react-router-dom'

const LinkComponent = () => {
  const navigate = useNavigate()
  const handleApple = () => {
    navigate('/ApplePlatform')
  }

  return (
    <div className="basis-2/3 h-full">
      <h2 className="text-2xl font-bold mb-6">연동</h2>
      <p className="mb-6">
        각 디렉토리 버튼 클릭 시 연동 방법 과정을 통해 각 디렉토리와 연동할 수
        있습니다.
      </p>
      <div className="flex justify-around">
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
          <img src={GooglePod} alt="Google Podcasts" className="mb-4" />
          <h3 className="text-xl font-bold">Google Podcasts</h3>
          <div className="flex items-center">
            <button className="text-blue-700 mt-2">연동하기</button>
            <img src={LinkIBlueIcon} alt="Link" className="ml-2" />
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
          <img src={ApplePod} alt="Apple Podcasts" className="mb-4" />
          <h3 className="text-xl font-bold">Apple Podcasts</h3>
          <div className="flex items-center" onClick={handleApple}>
            <button className="text-blue-700 mt-2">연동하기</button>
            <img src={LinkIBlueIcon} alt="Link" className="ml-2" />
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center">
          <img src={Spotify} alt="Spotify" className="mb-4" />
          <h3 className="text-xl font-bold">Spotify</h3>
          <div className="flex items-center">
            <button className="text-blue-700 mt-2">연동하기</button>
            <img src={LinkIBlueIcon} alt="Link" className="ml-2" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkComponent
