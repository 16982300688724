import React from 'react'

const SubContent = () => {
  return (
    <section className="py-16 bg-white">
      <div className="py-8 flex justify-center">
        <div className="bg-white p-6 border border-gray-200 rounded-lg shadow-lg w-full max-w-6xl">
          <div className="flex justify-between">
            <div className="flex-1 mx-4">
              <h4 className="mb-2 text-gray-700 font-semibold">회사소개</h4>
              <ul>
                <li>
                  <a href="/about" className="text-blue-700 hover:underline">
                    about
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1 mx-4">
              <h4 className="mb-2 text-gray-700 font-semibold">서비스소개</h4>
              <ul>
                <li>
                  <a href="/service" className="text-blue-700 hover:underline">
                    아이엠
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1 mx-4">
              <h4 className="mb-2 text-gray-700 font-semibold">고객지원</h4>
              <ul>
                <li>
                  <a href="/faq" className="text-blue-700 hover:underline">
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="/notice" className="text-blue-700 hover:underline">
                    공지사항
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1 mx-4">
              <h4 className="mb-2 text-gray-700 font-semibold">문의</h4>
              <ul>
                <li>
                  <a
                    href="mailto:austin125@naver.com"
                    className="text-blue-700 hover:underline"
                  >
                    iam@naver.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubContent
