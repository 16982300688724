import React, { useState } from 'react'
import useEpisodeCreateForm_v2 from '../../hooks/useEpisodeCreateForm_v2'

const EpisodeCreateForm_v2 = () => {
  const {
    handleAudioUpload,
    handleButtonClick,
    handleAudioRemove,
    handlePlayPause,
    handleSkipForward,
    handleSkipBackward,
    handlePlaybackRateChange,
    handleSave,
    handleBefore,
    handleAddRegion,
    handleRemoveRegion,
    currentTime,
    preAudio,
    playbackRate,
    waveformRef,
    audioInputRef,
  } = useEpisodeCreateForm_v2()

  const [ads, setAds] = useState([{ id: 1, time: 0, content: '광고' }])

  const addAdInput = () => {
    const newAd = {
      id: ads.length + 1,
      time: 0,
      content: '광고' + (ads.length + 1),
    }
    setAds([...ads, newAd])
    console.log(ads)
  }

  const timeChange = (index, e) => {
    ads[index].time = e
  }

  const contentChange = (index, e) => {
    ads[index].content = e
  }

  const handleAdRemove = (index) => {
    const newAds = ads.filter((_, i) => i !== index)
    setAds(newAds)
    handleRemoveRegion(index)
  }

  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">에피소드 만들기</h2>
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-blue-700 font-bold">에피소드</div>
          <div className="flex items-center space-x-2">
            <div className="h-2 w-2 bg-blue-700 rounded-full"></div>
            <div className="h-2 w-2 bg-blue-700 rounded-full"></div>
            <div className="h-2 w-2 bg-gray-300 rounded-full"></div>
          </div>
        </div>
        <div className="bg-gray-200 h-1 w-full rounded-full">
          <div
            className="bg-blue-700 h-1 rounded-full"
            style={{ width: '66%' }}
          ></div>
        </div>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">오디오 파일</label>
        {preAudio ? (
          <div>
            <div ref={waveformRef} className="w-full mb-2"></div>
            <div className="text-center mb-2">
              <span className="text-gray-700">
                현재 재생 시간: {currentTime.toFixed(2)}
              </span>
            </div>
            <div className="flex items-center space-x-2 justify-center mb-2">
              <button
                onClick={handleSkipBackward}
                className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
              >
                3초 뒤로
              </button>
              <button
                onClick={handlePlayPause}
                className="bg-blue-700 text-white py-1 px-3 rounded"
              >
                재생/정지
              </button>
              <button
                onClick={handleSkipForward}
                className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
              >
                3초 앞으로
              </button>
            </div>
            <div className="flex items-center space-x-2 justify-center">
              <label className="text-gray-700">재생 속도: </label>
              <select
                value={playbackRate}
                onChange={(e) => handlePlaybackRateChange(e.target.value)}
                className="border rounded p-1"
              >
                <option value={0.5}>0.5x</option>
                <option value={1.0}>1.0x</option>
                <option value={1.5}>1.5x</option>
                <option value={2.0}>2.0x</option>
              </select>
            </div>
            <button
              onClick={handleAudioRemove}
              className="mt-2 bg-red-700 text-white py-2 px-4 rounded"
            >
              X 오디오 삭제
            </button>
          </div>
        ) : (
          <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded">
            <button
              onClick={handleButtonClick}
              className="bg-blue-700 text-white py-2 px-4 rounded mb-2"
            >
              + 오디오 선택
            </button>
            <input
              type="file"
              accept="audio/*"
              ref={audioInputRef}
              onChange={handleAudioUpload}
              className="hidden"
            />
          </div>
        )}
      </div>
      {/*<div className="p-4 bg-white border border-gray-300 rounded">*/}
      {/*  <label className="block text-gray-700 mb-2">챕터 마크</label>*/}
      {/*  <div className="space-y-4">*/}
      {/*    <div className="flex items-center space-x-4">*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="1 (ex) 배경음"*/}
      {/*        className="flex-grow p-2 border rounded"*/}
      {/*      />*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="00:00:00"*/}
      {/*        className="w-24 p-2 border rounded"*/}
      {/*      />*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="00:00:00"*/}
      {/*        className="w-24 p-2 border rounded"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="flex items-center space-x-4">*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="2 배경음"*/}
      {/*        className="flex-grow p-2 border rounded"*/}
      {/*      />*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="00:06:00"*/}
      {/*        className="w-24 p-2 border rounded"*/}
      {/*      />*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="01:30:00"*/}
      {/*        className="w-24 p-2 border rounded"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <button className="mt-2 bg-blue-700 text-white py-2 px-4 rounded-full">*/}
      {/*    +*/}
      {/*  </button>*/}
      {/*</div>*/}
      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">광고 배치</label>
        <div className="space-y-4">
          {ads.map((ad, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                id={index}
                type="text"
                placeholder={`광고${index + 1} `}
                className="flex-grow p-2 border rounded"
                onChange={(e) => contentChange(index, e.target.value)}
              />
              <input
                id={index}
                type="text"
                placeholder="00:00:00"
                className="w-24 p-2 border rounded"
                onChange={(e) => timeChange(index, e.target.value)}
                // onChange={(e) => handleAddRegion(e.target.value)}
              />
              <button
                id={index}
                onClick={() =>
                  handleAddRegion(ads[index].time, ads[index].content, index)
                }
                className="bg-blue-700 text-white py-2 px-4 rounded ml-auto"
              >
                추가
              </button>
              <button
                id={index}
                onClick={() => handleAdRemove(index)}
                className="bg-blue-700 text-white py-2 px-4 rounded ml-auto"
              >
                삭제
              </button>
            </div>
          ))}
        </div>
        <button
          onClick={addAdInput}
          className="mt-2 bg-blue-700 text-white py-2 px-4 rounded-full"
        >
          +
        </button>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleBefore}
          className="bg-blue-700 text-white py-2 px-4 rounded"
        >
          이전
        </button>
        <button
          onClick={handleSave}
          className="bg-blue-700 text-white py-2 px-4 rounded ml-auto"
        >
          다음
        </button>
      </div>
    </div>
  )
}

export default EpisodeCreateForm_v2
