import React, { useState } from 'react'
import AudioPlayer from './AudioPlayer'
import useEpisodeListDetail from '../../hooks/useEpisodeListDetail'

const EpisodeListDetail = () => {
  const {
    handleButtonClick,
    handleImageUpload,
    handleAddRegion,
    handleRemoveRegion,
    episodeDetail,
    image,
    audio,
    fileInputRef,
  } = useEpisodeListDetail()

  const [ads, setAds] = useState([{ id: 1, time: 0, content: '광고' }])

  const addAdInput = () => {
    const newAd = {
      id: ads.length + 1,
      time: 0,
      content: '광고' + (ads.length + 1),
    }
    setAds([...ads, newAd])
    console.log(ads)
  }

  const timeChange = (index, e) => {
    ads[index].time = e
  }

  const contentChange = (index, e) => {
    ads[index].content = e
  }

  const handleAdRemove = (index) => {
    const newAds = ads.filter((_, i) => i !== index)
    setAds(newAds)
    handleRemoveRegion(index)
  }

  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">에피소드 관리하기</h2>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">에피소드 제목</label>
        <p className="w-full p-2 border rounded">{episodeDetail.title}</p>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">에피소드 이미지</label>
        <div className="flex items-center">
          {image ? (
            <div className="mr-4">
              <img
                src={image}
                alt="Episode"
                className="w-24 h-24 object-cover rounded"
              />
            </div>
          ) : (
            <div className="mr-4">
              <div className="w-24 h-24 border-dashed border-2 border-gray-300 flex items-center justify-center rounded">
                <span className="text-gray-500">미리보기</span>
              </div>
            </div>
          )}
          <div>
            {!image && (
              <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded mb-2">
                <button
                  onClick={handleButtonClick}
                  className="bg-blue-700 text-white py-2 px-4 rounded mb-2"
                >
                  + 사진첨부
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </div>
            )}
          </div>
        </div>
        <p className="text-gray-500 text-sm mt-2">
          1,400 ~ 3,000 픽셀의 정사각형 사진을 첨부해주세요. (jpg, png)
        </p>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">에피소드 설명</label>
        <p className="w-full p-2 border rounded">{episodeDetail.description}</p>
      </div>
      <AudioPlayer audioFile={audio} />
      {/*<div className="p-4 bg-white border border-gray-300 rounded">*/}
      {/*  <label className="block text-gray-700 mb-2">챕터 마크</label>*/}
      {/*  <div className="space-y-4">*/}
      {/*    <div className="flex items-center space-x-4">*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="1 (ex) 배경음"*/}
      {/*        className="flex-grow p-2 border rounded"*/}
      {/*      />*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="00:00:00"*/}
      {/*        className="w-24 p-2 border rounded"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="flex items-center space-x-4">*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="2 배경음"*/}
      {/*        className="flex-grow p-2 border rounded"*/}
      {/*      />*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        placeholder="00:06:00"*/}
      {/*        className="w-24 p-2 border rounded"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <button className="mt-2 bg-blue-700 text-white py-2 px-4 rounded-full">*/}
      {/*    +*/}
      {/*  </button>*/}
      {/*</div>*/}
      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">광고 배치</label>
        <div className="space-y-4">
          {ads.map((ad, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                id={index}
                type="text"
                placeholder={`광고${index + 1} `}
                className="flex-grow p-2 border rounded"
                onChange={(e) => contentChange(index, e.target.value)}
              />
              <input
                id={index}
                type="text"
                placeholder="00:00:00"
                className="w-24 p-2 border rounded"
                onChange={(e) => timeChange(index, e.target.value)}
                // onChange={(e) => handleAddRegion(e.target.value)}
              />
              <button
                id={index}
                onClick={() =>
                  handleAddRegion(ads[index].time, ads[index].content, index)
                }
                className="bg-blue-700 text-white py-2 px-4 rounded ml-auto"
              >
                추가
              </button>
              <button
                id={index}
                onClick={() => handleAdRemove(index)}
                className="bg-blue-700 text-white py-2 px-4 rounded ml-auto"
              >
                삭제
              </button>
            </div>
          ))}
        </div>
        <button
          onClick={addAdInput}
          className="mt-2 bg-blue-700 text-white py-2 px-4 rounded-full"
        >
          +
        </button>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">연령제한 컨텐츠</label>
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" />
          <span className="text-gray-700">연령제한이 포함된 컨텐츠입니다.</span>
        </div>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">업로드 날짜 및 시간</label>
        <div className="flex space-x-4">
          <input type="date" className="p-2 border rounded" />
          <input type="time" className="p-2 border rounded" />
        </div>
      </div>
    </div>
  )
}

export default EpisodeListDetail
