// import React, { useState } from 'react';
// import axios from 'axios';
//
// const UpdateChannelInfo = () => {
//     const [file, setFile] = useState(null);
//     const [channelInfo, setChannelInfo] = useState({
//         username: "이",
//         channelTitle: "채널 제목",
//         channelDescription: "채널 설명",
//         channelCategory: "여가",
//         channelDetailCategory: "독서",
//         ageLimit: false
//     });
//
//     const handleFileChange = (e) => {
//         setFile(e.target.files[0]);
//     };
//
//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setChannelInfo({
//             ...channelInfo,
//             [name]: value,
//         });
//     };
//
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//
//         const formData = new FormData();
//         formData.append('image', file);
//         formData.append('ChannelSaveRequestDto', JSON.stringify(channelInfo));
//
//         try {
//             const response = await axios.patch('http://localhost:8080/channel', formData, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 withCredentials: true
//             });
//             console.log('Response:', response.data);
//         } catch (error) {
//             console.error('Error updating channel info:', error);
//         }
//     };
//
//     return (
//         <div>
//             <h2>Update Channel Info</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Channel Name:</label>
//                     <input
//                         type="text"
//                         name="name"
//                         value={channelInfo.name}
//                         onChange={handleInputChange}
//                     />
//                 </div>
//                 <div>
//                     <label>Channel Description:</label>
//                     <input
//                         type="text"
//                         name="description"
//                         value={channelInfo.description}
//                         onChange={handleInputChange}
//                     />
//                 </div>
//                 <div>
//                     <label>Upload Image:</label>
//                     <input type="file" onChange={handleFileChange} />
//                 </div>
//                 <button type="submit">Update Channel</button>
//             </form>
//         </div>
//     );
// };
//
// export default UpdateChannelInfo;

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { XMLParser } from 'fast-xml-parser'

const RssFeed = () => {
  const [rssData, setRssData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchRssData = async () => {
      try {
        const response = await axios.get(
          'http://localhost:8080/episode/rssfeed',
          {
            headers: {
              'Content-Type': 'application/xml',
            },
            withCredentials: true,
          },
        )

        const parser = new XMLParser({
          ignoreAttributes: false,
          attributeNamePrefix: '',
        })
        const result = parser.parse(response.data)

        const channelData = result.rss.channel
        // Check if item is an array, if not convert it to an array
        if (!Array.isArray(channelData.item)) {
          channelData.item = [channelData.item]
        }

        setRssData(channelData)
        setLoading(false)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    }

    fetchRssData()
  }, [])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <div>
      <h1>{rssData.title}</h1>
      <p>{rssData.description}</p>
      {rssData['itunes:image'] && (
        <img src={rssData['itunes:image'].href} alt="Podcast" />
      )}
      <a href={rssData.link} target="_blank" rel="noopener noreferrer">
        Visit Podcast
      </a>
      <div>
        <h2>Episodes</h2>
        {rssData.item &&
          rssData.item.map((item, index) => (
            <div key={index}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <p>Author: {item['itunes:author']}</p>
              <p>Published: {item.pubDate}</p>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                Listen
              </a>
            </div>
          ))}
      </div>
    </div>
  )
}

export default RssFeed
