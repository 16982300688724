import React from 'react'
import { Link } from 'react-router-dom'
import {
  IAM,
  Page,
  Create,
  Revenue,
  Manage,
  Advertise,
  Chart,
  LinkIcon,
} from '../../asset/icon'

const OutLineSideBar = () => {
  return (
    <aside className="w-64 bg-blue-700 text-white p-5 flex flex-col items-center mt-20">
      <div className="flex justify-center items-end h-20 mb-16">
        <Link to="/" className="flex items-end">
          <img src={IAM} alt="IAM Icon" className="cursor-pointer" />
        </Link>
      </div>

      <div>
        <ul className="list-none p-0">
          <li className="mb-5 flex items-center">
            <img src={Page} alt="Page Icon" className="mr-2" />
            <Link
              to="/NewMyPage"
              className="text-white text-lg no-underline hover:underline"
            >
              마이페이지
            </Link>
          </li>
          <li className="mb-5 flex items-center">
            <img src={Create} alt="Union Icon" className="mr-2" />
            <Link
              to="/NewEpisodeCreate_v1"
              className="text-white text-lg no-underline hover:underline"
            >
              에피소드 만들기
            </Link>
          </li>
          <li className="mb-5 flex items-center">
            <img src={Manage} alt="Manage Icon" className="mr-2" />
            <Link
              to="/NewEpisodeManagement"
              className="text-white text-lg no-underline hover:underline"
            >
              에피소드 관리하기
            </Link>
          </li>
          <li className="mb-5 flex items-center">
            <img src={Advertise} alt="Advertise Icon" className="mr-2" />
            <Link
              to="/NewAdList"
              className="text-white text-lg no-underline hover:underline"
            >
              광고
            </Link>
          </li>
          <li className="mb-5 flex items-center">
            <img src={Chart} alt="Chart Icon" className="mr-2" />
            <Link
              to="/NewMyPage"
              className="text-white text-lg no-underline hover:underline"
            >
              에피소드 통계
            </Link>
          </li>
          <li className="mb-5 flex items-center">
            <img src={Revenue} alt="Revenue Icon" className="mr-2" />
            <Link
              to="/NewMyPage"
              className="text-white text-lg no-underline hover:underline"
            >
              수익
            </Link>
          </li>
          <li className="mb-5 flex items-center">
            <img src={LinkIcon} alt="Link Icon" className="mr-2" />
            <Link
              to="/Link"
              className="text-white text-lg no-underline hover:underline"
            >
              연동
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default OutLineSideBar
