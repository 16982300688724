import React from 'react'
import { IAM } from '../../asset/icon'

const OutLineSideBarClean = () => {
  return (
    <aside className="w-64 bg-blue-700 text-white p-5 flex flex-col mt-20">
      <div className="flex justify-center items-end h-20">
        <img src={IAM} alt="IAM Icon" />
      </div>
    </aside>
  )
}

export default OutLineSideBarClean
