import React from 'react'

const AdDetailComponent = () => {
  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">광고</h2>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <h3 className="text-lg font-semibold mb-2">지정된 광고</h3>
        <div className="flex items-center justify-between p-4 bg-white border border-gray-300 rounded mb-4">
          <div className="flex items-center space-x-4">
            <img
              src="http://via.placeholder.com/50"
              alt="광고"
              className="w-16 h-16 rounded-full"
            />
            <div>
              <div className="text-lg font-semibold">배너의 전략</div>
              <div className="text-gray-500">
                배너의 전략 설명 텍스트가 여기에 표시됩니다.
              </div>
            </div>
          </div>
          <button className="text-2xl font-bold bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center">
            +
          </button>
        </div>
        <div className="p-4 bg-white border border-gray-300 rounded">
          <div className="flex items-center space-x-4">
            <img
              src="http://via.placeholder.com/50"
              alt="광고"
              className="w-16 h-16 rounded-full"
            />
            <div>
              <div className="text-lg font-semibold">배너의 전략</div>
              <div className="text-gray-500">
                배너의 전략 설명 텍스트가 여기에 표시됩니다.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <h3 className="text-lg font-semibold mb-2">오디오 파일</h3>
        <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded">
          <button className="bg-blue-700 text-white py-2 px-4 rounded mb-2">
            + 오디오 선택
          </button>
          <button className="bg-red-700 text-white py-2 px-4 rounded">
            X 오디오 삭제
          </button>
        </div>
      </div>
    </div>
  )
}

export default AdDetailComponent
