import React from 'react'

const AdMoreComponent = () => {
  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">광고</h2>
      <div className="p-4 bg-white border border-gray-300 rounded">
        <h2 className="text-lg font-bold mb-4">대사형 광고</h2>
        <div className="p-4 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <img
                src="https://via.placeholder.com/100"
                alt="Ad Image"
                className="rounded-full"
              />
              <div className="ml-4">
                <h3 className="text-xl font-bold">배달의 민족</h3>
                <p>계약일 : XXXX.XX.XX-XXXX.XX.XX</p>
                <p>재생 횟수 - 총 리워드 : 10회</p>
                <p>요구 사항 : 없음</p>
              </div>
            </div>
            <button className="bg-gray-100 text-black py-1 px-4 rounded-full flex items-center">
              수락 취소
            </button>
          </div>
          <div className="p-4 bg-white border border-gray-300 rounded">
            <h3 className="text-base font-bold mb-2">대본</h3>
            <p>
              삼성 전자에서 새로 출시된 아이폰 15는 배터리 어쩌구 저쩌구 음향이
              좋고 새로운 감성과 애어팟이 새로운 느낌의 광고를 레드콤과 함께
              촬영한 새로운 전자의 느낌을...
            </p>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">오디오 파일</label>
        <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded">
          <button className="bg-blue-700 text-white py-2 px-4 rounded mb-2">
            + 오디오 선택
          </button>
          <input type="file" accept="audio/*" className="hidden" />
        </div>
      </div>
    </div>
  )
}

export default AdMoreComponent
