import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const useMyPageModify = () => {
  const ip = process.env.REACT_APP_URL
  const [profile, setProfile] = useState([])
  const [profileImage, setProfileImage] = useState()
  const [preProfileImage, setPreProfileImage] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [categorySubList, setCategorySubList] = useState([])
  const [activeIndex, setActiveIndex] = useState()
  const [activeSubIndex, setActiveSubIndex] = useState()
  const fileInputRef = useRef(null)
  const [isValid, setIsValid] = useState(null)
  const [checked, setChecked] = useState()
  const formData = new FormData()

  //ref
  const imageRef = useRef(null)
  const podCastRef = useRef(null)
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  const categoryRef = useRef(null)
  const subCategoryRef = useRef(null)
  const checkRef = useRef(null)

  const navigate = useNavigate()

  useEffect(() => {
    handleCategory()
    handleProfile()
  }, [])

  const handleProfile = async () => {
    try {
      await axios
        .get(`${ip}/channel`, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then((response) => {
          setProfile(response.data)
          setProfileImage(response.data.channelImage)
          setPreProfileImage(response.data.channelImage)
          setActiveIndex(response.data.channelCategories)
          setActiveSubIndex(response.data.channelDetailCategories)
          setChecked(response.data.ageLimit)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategory = async () => {
    try {
      await axios
        .get(`${ip}/category`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setCategoryList(response.data.list)
          handleCategorySub(response.data.list[0].name)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategorySub = async (name) => {
    try {
      await axios
        .get(`${ip}/category/sub`, {
          params: { name: name },
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setCategorySubList(response.data.list)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleCategoryClick = (index, name) => {
    setActiveIndex(name)
    categoryRef.current.value = name
    handleCategorySub(name)
  }

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  // const handleSubButtonClick = (name) => {
  //   setActiveSubIndex(name)
  //   subCategoryRef.current.value = name
  // }

  // SubCategory 활성 상태를 관리하는 배열 상태 추가
  const [activeSubIndices, setActiveSubIndices] = useState([])

  // 서브 카테고리 클릭 핸들러 수정
  const handleSubButtonClick = (name) => {
    subCategoryRef.current.value = name
    if (activeSubIndices.includes(name)) {
      setActiveSubIndices(activeSubIndices.filter((item) => item !== name))
    } else {
      setActiveSubIndices([...activeSubIndices, name])
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreProfileImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
    setProfileImage(event.target.files[0])
  }

  const handleImageRemove = () => {
    setPreProfileImage(null)
  }

  const handlePodcastName = async () => {
    try {
      await axios
        .get(`${ip}/member/check`, {
          params: { username: nameRef.current.value },
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setIsValid(response.data)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  //input 수정
  const handlePodcastNameChange = async (e) => {
    // profile.channelTitle = e.target.value;
    podCastRef.current.value = e.target.value
  }

  const handleNameChange = async (e) => {
    // profile.username = e.target.value;
    nameRef.current.value = e.target.value
  }

  const handleDescriptionChange = async (e) => {
    // profile.channelDescription = e.target.value;
    descriptionRef.current.value = e.target.value
  }

  const handleCheckboxChange = (e) => {
    // profile.ageLimit = e.target.checked;
    // setChecked(!checked);
    checkRef.current.value = e.target.checked
  }

  //저장
  const handleSave = async () => {
    const ChannelSaveRequestDto = {
      username: nameRef.current.value,
      channelTitle: podCastRef.current.value,
      channelDescription: descriptionRef.current.value, // JSON 문자열로 변환 시 문자열로 설정
      channelCategories: [categoryRef.current.value],
      channelDetailCategories: [subCategoryRef.current.value],
      ageLimit: false,
    }

    //  const response = await axios.get( profile.channelImage, { responseType: 'arraybuffer' });
    //  const base64Image = base64.fromByteArray(new Uint8Array(response.data));
    //  formData.append('image', base64Image);
    // formData.append('image', profile.channelImage);
    formData.append('image', profileImage)
    formData.append(
      'ChannelSaveRequestDto',
      JSON.stringify(ChannelSaveRequestDto),
    )

    try {
      await axios
        .patch(`${ip}/channel`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        })
        .then(() => {
          navigate('/NewMyPage')
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  return {
    handleProfile,
    handleCategory,
    handleCategorySub,
    handleCategoryClick,
    handleImageClick,
    handleSubButtonClick,
    handleImageUpload,
    handleImageRemove,
    handlePodcastName,
    handlePodcastNameChange,
    handleNameChange,
    handleDescriptionChange,
    handleCheckboxChange,
    handleSave,
    profile,
    profileImage,
    preProfileImage,
    categoryList,
    categorySubList,
    activeIndex,
    activeSubIndex,
    fileInputRef,
    isValid,
    checked,
    formData,
    imageRef,
    podCastRef,
    nameRef,
    descriptionRef,
    categoryRef,
    subCategoryRef,
    checkRef,
    activeSubIndices,
  }
}

export default useMyPageModify
