import React from 'react'
import ReactApexChart from 'react-apexcharts'

class PieChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [44, 55, 41, 17, 15],
      options: {
        chart: {
          type: 'donut',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
        plotOptions: {
          pie: {
            donut: {
              label: 'content',
              show: true,
              // labels: {
              //     show: true,
              // name:{
              //     show: true,
              // },
              // total: {
              //     show: true,
              //     showAlways: true,
              //     label: '내용을 여기에 입력하세요',
              //     fontSize: '20px',
              //     color: '#000'
              // }
              // }
            },
          },
        },
      },
    }
  }

  render() {
    return (
      <div>
        <div id="chart" style={{ width: '700px', height: '400px' }}>
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="donut"
          />
        </div>
      </div>
    )
  }
}

export default PieChart
