import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

const useEpisodeCreateForm_v1 = () => {
  const location = useLocation()
  const imageData = location.state?.imageData
  const audio = location.state?.audio
  const navigate = useNavigate()
  const [preImage, setPreImage] = useState(null) //화면에 뜨는 값
  const [image, setImage] = useState(null) //sessionStorage에 split 처리되어 저장되는 값
  const [audioFile, setAudioFile] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const fileInputRef = useRef(null)
  const [sessionImage, setSessionImage] = useState()

  useEffect(() => {
    const savedTitle = sessionStorage.getItem('episodeTitle')
    // if (sessionStorage.getItem('episodeImage') !== 'null') {
    //   console.log('not null')
    //   setSessionImage(sessionStorage.getItem('episodeImage'))
    //
    //   const savedImage = sessionStorage.getItem('episodeImage')
    //
    //   if (savedImage) {
    //     const fileData = JSON.parse(savedImage)
    //     const base64String = `data:${fileData.type};base64,${fileData.base64}`
    //
    //     // Base64 문자열을 Blob으로 변환
    //     fetch(base64String)
    //       .then((res) => res.blob())
    //       .then((blob) => {
    //         const file = new File([blob], fileData.name, {
    //           type: fileData.type,
    //         })
    //         const url = URL.createObjectURL(file)
    //         setPreImage(url)
    //       })
    //   }
    // }
    if (imageData) {
      setImage(imageData)
      const url = URL.createObjectURL(imageData)
      setPreImage(url)
    }
    if (audio) {
      setAudioFile(audio)
    }
    const savedDescription = sessionStorage.getItem('episodeDescription')

    if (savedTitle) {
      setTitle(savedTitle)
    }

    if (savedDescription) {
      setDescription(savedDescription)
    }
  }, [])

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    setImage(event.target.files[0])
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        // const base64String = reader.result.split(',')[1] // Base64 부분만 저장
        // const fileData = {
        //   base64: base64String,
        //   name: file.name,
        //   type: file.type,
        //   size: file.size,
        // }
        // setImageData(fileData)
        setPreImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleImageRemove = () => {
    setPreImage(null)
    // setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  const handleSave = () => {
    sessionStorage.setItem('episodeTitle', title)
    // if (!sessionStorage.getItem('episodeImage')) {
    //   sessionStorage.setItem('episodeImage', JSON.stringify(imageData))
    // }
    sessionStorage.setItem('episodeDescription', description)
    navigate('/NewEpisodeCreate_v2', { state: { image, audioFile } })
  }

  return {
    handleImageUpload,
    handleButtonClick,
    handleImageRemove,
    handleSave,
    preImage,
    sessionImage,
    setSessionImage,
    title,
    setTitle,
    description,
    setDescription,
    fileInputRef,
  }
}

export default useEpisodeCreateForm_v1
