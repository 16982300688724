import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import axios from 'axios'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

const useEpisodeCreateForm_v3 = () => {
  const location = useLocation()
  const audioFile = location.state?.audio
  const imageData = location.state?.imageData
  const ip = process.env.REACT_APP_URL
  const navigate = useNavigate()
  const [audio, setAudio] = useState(null)
  const [image, setImage] = useState(null)
  const [preImage, setPreImage] = useState(null)
  const [preAudio, setPreAudio] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [playbackRate, setPlaybackRate] = useState(1.0)
  const waveformRef = useRef(null)
  const audioInputRef = useRef(null)
  const wavesurferRef = useRef(null)
  const fileInputRef = useRef(null)
  const [age] = useState(true)
  const [uploadType, setUploadType] = useState(1)
  const [adIndex, setAdIndex] = useState([])

  //
  const [immediateUpload, setImmediateUpload] = useState(true)
  const [scheduleUpload, setScheduleUpload] = useState(false)
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [currentTime, setCurrentTime] = useState(0)

  const handleImmediateUploadChange = (e) => {
    setImmediateUpload(e.target.checked)
    if (e.target.checked) {
      setScheduleUpload(false)
    }
    setUploadType(1)
  }

  const handleScheduleUploadChange = (e) => {
    setScheduleUpload(e.target.checked)
    if (e.target.checked) {
      setImmediateUpload(false)
    }
    setUploadType(0)
  }

  useEffect(() => {
    const savedTitle = sessionStorage.getItem('episodeTitle')
    // if (sessionStorage.getItem('episodeImage') !== 'null') {
    //   setSessionImage(sessionStorage.getItem('episodeImage'))
    //   const savedImage = sessionStorage.getItem('episodeImage')
    //   if (savedImage) {
    //     const fileData = JSON.parse(savedImage)
    //     const base64String = `data:${fileData.type};base64,${fileData.base64}`
    //
    //     // Base64 문자열을 Blob으로 변환
    //     fetch(base64String)
    //       .then((res) => res.blob())
    //       .then((blob) => {
    //         const file = new File([blob], fileData.name, {
    //           type: fileData.type,
    //         })
    //         setImage(file)
    //         const url = URL.createObjectURL(file)
    //         setPreImage(url)
    //       })
    //   }
    // }

    const savedDescription = sessionStorage.getItem('episodeDescription')

    if (savedTitle) {
      setTitle(savedTitle)
    }

    if (savedDescription) {
      setDescription(savedDescription)
    }
  }, [])

  useEffect(() => {
    if (imageData) {
      setImage(imageData)
      const url = URL.createObjectURL(imageData)
      setPreImage(url)
    }

    if (audioFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreAudio(reader.result)
      }
      reader.readAsDataURL(audioFile)
      setAudio(audioFile)
    }
  }, [])

  useEffect(() => {
    if (preAudio && waveformRef.current) {
      const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#0d6efd',
        progressColor: '#0d6efd',
        height: 100,
        responsive: true,
        barWidth: 2,
        barHeight: 1,
        barGap: 2,
        plugins: [RegionsPlugin.create()], // RegionsPlugin 추가
      })

      wavesurferRef.current = wavesurfer

      const updateCurrentTime = () => {
        setCurrentTime(wavesurfer.getCurrentTime())
      }

      wavesurfer.on('audioprocess', updateCurrentTime)
      wavesurfer.on('seek', updateCurrentTime)

      wavesurfer.load(preAudio)
      wavesurfer.setPlaybackRate(playbackRate)

      return () => wavesurfer.destroy()
    }
  }, [preAudio])

  const handleAudioUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreAudio(reader.result)
      }
      reader.readAsDataURL(file)
    }
    setAudio(event.target.files[0])
  }

  const handleButtonClick = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleAudioRemove = () => {
    setAudio(null)
    setPreAudio(null)
    if (audioInputRef.current) {
      audioInputRef.current.value = null
    }
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy()
      wavesurferRef.current = null
    }
  }

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause()
    }
  }

  const handleSkipBackward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime - 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handleSkipForward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime + 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handlePlaybackRateChange = (rate) => {
    if (wavesurferRef.current) {
      wavesurferRef.current.setPlaybackRate(rate)
      setPlaybackRate(rate)
    }
  }

  const handleSubmit = async () => {
    const episodeData = {
      title: title,
      description: description,
      age: age.toString(), // JSON 문자열로 변환 시 문자열로 설정
      upload: uploadType,
    }

    const formData = new FormData()
    formData.append('image', image)
    formData.append('audio', audio)
    formData.append('episodeData', JSON.stringify(episodeData))

    console.log(formData.get('image')) // 디버깅을 위해 추가

    try {
      await axios.post(`${ip}/episode`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  const handleBefore = () => {
    navigate('/NewEpisodeCreate_v2', { state: { audioFile, image } })
  }

  const handleSave = () => {
    handleSubmit()
    sessionStorage.clear()
    navigate('/NewEpisodeManagement')
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1] // Base64 부분만 저장
        const fileData = {
          base64: base64String,
          name: file.name,
          type: file.type,
          size: file.size,
        }
        setImage(fileData)
        sessionStorage.setItem('episodeImage', JSON.stringify(fileData))
        setPreImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleImageRemove = () => {
    setPreImage(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }

  // if문이 null이 뜸 -> 이거 해결하면 수정, 삭제 가능한데 모르겠음.
  const getRegionById = (id) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (regionsPlugin && regionsPlugin.regions && regionsPlugin.regions.list) {
      return Object.values(regionsPlugin.regions.list).find(
        (region) => region.id === id,
      )
    }
    return null
  }

  const handleAddRegion = (startTime, content, index) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (!regionsPlugin) {
      console.error('RegionsPlugin is not active')
      return
    }

    const existingRegion = getRegionById(index)

    if (existingRegion) {
      console.log('include')
      existingRegion.update({
        start: startTime,
        content: content,
        color: '#000000',
      })
    } else {
      console.log('not include')
      setAdIndex([...adIndex, index])
      regionsPlugin.addRegion({
        id: index,
        start: startTime,
        content: content,
        color: '#000000',
      })
    }
  }

  const handleRemoveRegion = (index) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (!regionsPlugin) {
      console.error('RegionsPlugin is not active')
      return
    }
    console.log('remove be')
    const regionToRemove = getRegionById(index)
    if (regionToRemove) {
      console.log('remove suc')
      regionToRemove.remove()
      setAdIndex(adIndex.filter((id) => id !== index))
    }
  }

  return {
    handleImmediateUploadChange,
    handleScheduleUploadChange,
    handleAudioUpload,
    handleButtonClick,
    handleAudioRemove,
    handlePlayPause,
    handleSkipBackward,
    handleSkipForward,
    handlePlaybackRateChange,
    handleSubmit,
    handleSave,
    handleImageUpload,
    handleImageRemove,
    handleBefore,
    handleRemoveRegion,
    handleAddRegion,
    audio,
    image,
    preImage,
    preAudio,
    title,
    description,
    playbackRate,
    waveformRef,
    audioInputRef,
    wavesurferRef,
    fileInputRef,
    age,
    uploadType,
    immediateUpload,
    scheduleUpload,
    currentTime,
    date,
    setDate,
    time,
    setTime,
  }
}

export default useEpisodeCreateForm_v3
