import React from 'react'

const ApplePlatformComponent = () => {
  const handleCopyClick = () => {
    const rssInput = document.getElementById('rssInput')
    rssInput.select()
    document.execCommand('copy')
    alert('RSS 피드 주소가 복사되었습니다.')
  }

  return (
    <div className="w-full p-5">
      <h2 className="text-2xl font-bold mb-6">
        내 팟캐스트를 애플 팟캐스트와 연동하기
      </h2>

      <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h3 className="text-xl font-semibold mb-2">
          step1 : Apple ID 생성하기
        </h3>
        <p className="text-gray-600 mb-4">
          Apple Podcasts에 팟캐스트를 제출하려면 Apple ID가 필요합니다.
        </p>
        <div className="flex items-center mb-4">
          <div className="bg-gray-100 p-4 rounded-full mr-4">
            <img
              src="https://via.placeholder.com/50"
              alt="Apple Logo"
              className="w-12 h-12"
            />
          </div>
          <div className="flex flex-col">
            <button className="bg-blue-600 text-white py-2 px-4 rounded mb-2 flex items-center">
              <span className="mr-2">Apple ID 생성하기</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M12.293 6.293a1 1 0 011.414 0L16 8.586V5a1 1 0 112 0v7a1 1 0 01-1 1H10a1 1 0 010-2h3.586l-2.293-2.293a1 1 0 010-1.414z" />
                <path d="M3 8a1 1 0 011-1h7a1 1 0 110 2H5v4h4a1 1 0 110 2H4a1 1 0 01-1-1V8z" />
              </svg>
            </button>
            <p className="text-gray-500">
              기존에 Apple ID를 생성하셨다면 Step 2로 바로 넘어가시면 됩니다.
            </p>
            <p className="text-gray-500">
              Apple ID가 없을 시 아래 링크를 통하여 Apple ID를 생성하신 후 Step
              2로 넘어가시면 됩니다.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h3 className="text-xl font-semibold mb-2">
          step2 : 내 RSS 피드 주소 복사하기
        </h3>
        <p className="text-gray-600 mb-4">
          Apple Podcasts에 팟캐스트를 제출할 때 본인의 RSS피드 URL 주소가
          필요합니다.
        </p>
        <div className="flex items-center mb-4">
          <input
            id="rssInput"
            type="text"
            value="http://dfadasfdafdsa"
            className="w-full p-2 border border-gray-300 rounded mr-2"
            readOnly
          />
          <button
            onClick={handleCopyClick}
            className="bg-blue-600 text-white py-2 px-4 rounded"
          >
            복사
          </button>
        </div>
        <p className="text-gray-500">
          Apple Podcasts에서 요구하는 URL은 아래 혹은 우측 복사 ‘버튼’을 통해
          복사하실 수 있습니다.
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h3 className="text-xl font-semibold mb-2 ">
          step3 : 내 RSS 피드를 Apple Podcasts에 제출하기
        </h3>
        <p className="text-gray-600 mb-4">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          'Apple Podcasts Connect' 웹사이트에서 아래 설명을 따라 쉽게 내
          RSS피드를 Apple Podcasts에 제출할 수 있습니다.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mb-4 flex justify-between items-center">
          <p className="text-gray-600">
            ‘Apple Podcasts Connect’ 웹사이트에서 아래 설명을 따라 쉽게 내
            RSS피드를 Apple Podcasts에 제출할 수 있습니다.
          </p>
          <div className="flex items-center space-x-2">
            <button className="bg-white text-blue-700 py-2 px-4 rounded-full border border-gray-300 shadow-md">
              1/6
            </button>
            <button className="bg-gray-200 text-gray-700 py-2 px-3 rounded-full">
              ←
            </button>
            <button className="bg-gray-200 text-gray-700 py-2 px-3 rounded-full">
              →
            </button>
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-gray-100 p-4 rounded-full mr-4">
            <img
              src="https://via.placeholder.com/50"
              alt="Apple Logo"
              className="w-12 h-12"
            />
          </div>
          <div className="flex flex-col">
            <button className="bg-blue-600 text-white py-2 px-4 rounded mb-2 flex items-center">
              <span className="mr-2">Apple ID 생성하기</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M12.293 6.293a1 1 0 011.414 0L16 8.586V5a1 1 0 112 0v7a1 1 0 01-1 1H10a1 1 0 010-2h3.586l-2.293-2.293a1 1 0 010-1.414z" />
                <path d="M3 8a1 1 0 011-1h7a1 1 0 110 2H5v4h4a1 1 0 110 2H4a1 1 0 01-1-1V8z" />
              </svg>
            </button>
            <p className="text-gray-500">
              Apple Podcasts Connect에서 더 많은 단계를 수행하세요.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h3 className="text-xl font-semibold mb-2">
          step4 : Apple Podcasts를 아이앱에 제출하기
        </h3>
        <p className="text-gray-600 mb-4">
          아이앱에서 Apple Podcasts에 팟캐스트를 제출할 때 본인의 RSS피드 URL
          주소가 필요합니다.
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mb-4">
          <img
            src="https://via.placeholder.com/200x150"
            alt="Guide"
            className="w-full mb-4"
          />
          <p className="text-gray-600">
            아이앱에서 Apple Podcasts에 팟캐스트를 인증 등록 확인하실 URL 주소가
            필요합니다.
          </p>
        </div>
        <div className="flex items-center mb-4">
          <input
            id="rssInput"
            type="text"
            value="http://dfadasfdafdsa"
            className="w-full p-2 border border-gray-300 rounded mr-2"
            readOnly
          />
          <button
            onClick={handleCopyClick}
            className="bg-blue-600 text-white py-2 px-4 rounded"
          >
            복사
          </button>
        </div>
        <p className="text-gray-500">
          아이앱에서 Apple Podcasts에 인증 등록 확인하실 URL 주소가 필요합니다.
        </p>
      </div>
    </div>
  )
}

export default ApplePlatformComponent
