import React from 'react'
import { MainPoint1, MainPoint2, MainPoint3 } from '../../asset/icon'

const MainContent = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="max-w-5xl mx-auto px-4 space-y-16">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-semibold mb-4">
            정치, 경제, 시사에서 벗어난 다양한 주제
          </h2>
          <img src={MainPoint1} alt="Category" className="w-full md:w-2/3" />
        </div>
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-semibold mb-4">연계 서비스</h2>
          <img
            src={MainPoint2}
            alt="Connect Service"
            className="w-full md:w-2/3"
          />
        </div>
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-semibold mb-4">수익 창출</h2>
          <img src={MainPoint3} alt="Revenue" className="w-full md:w-2/3" />
        </div>
      </div>
    </section>
  )
}

export default MainContent
