import React from 'react'
import { useInView } from 'react-intersection-observer'

const FeaturesSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // 요소가 10% 보일 때 트리거
  })

  return (
    <section className="py-16 bg-white text-left">
      <div
        ref={ref}
        className={`max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 transform transition-all duration-1000 ${
          inView ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
        }`}
      >
        <div className="border-t-2 border-blue-500 pt-4 text-left">
          <p className="text-2xl font-semibold mb-2">
            전문적인 팟캐스트 호스팅 에피소드 생성 및 편집을 위한 편리하고
            직관적인 기능
          </p>
        </div>
        <div className="border-t-2 border-blue-500 pt-4 text-left">
          <p className="text-2xl font-semibold mb-2">
            광고 녹음 및 송출을 통한 수익 창출 기능
          </p>
        </div>
        <div className="border-t-2 border-blue-500 pt-4 text-left">
          <p className="text-2xl font-semibold mb-2">
            에피소드를 스트리밍 플랫폼으로 내보내기 위한 연계 기능
          </p>
        </div>
        <div className="border-t-2 border-blue-500 pt-4 text-left">
          <p className="text-2xl font-semibold mb-2">
            내 팟캐스트 성장을 위한 정확한 통계분석과 매니지먼트
          </p>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
