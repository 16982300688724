import React from 'react'
import useEpisodeList from '../../hooks/useEpisodeList'

const EpisodeList = () => {
  const {
    handleTabClick,
    handleEpisodeDetail,
    handleEpisodeCreate,
    activeTab,
    episodeList,
  } = useEpisodeList()

  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">에피소드 관리하기</h2>
      <div className="flex mb-4">
        <button
          className={`py-2 px-4 mr-2 rounded ${activeTab === '1' ? 'bg-blue-700 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick('1')}
        >
          업로드 된 에피소드
        </button>
        <button
          className={`py-2 px-4 mr-2 rounded ${activeTab === '-1' ? 'bg-blue-700 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick('-1')}
        >
          임시 저장 에피소드
        </button>
        <button
          className={`py-2 px-4 rounded ${activeTab === '0' ? 'bg-blue-700 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => handleTabClick('0')}
        >
          업로드 예약 에피소드
        </button>
      </div>
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="제목, 내용"
          className="flex-grow border p-2 rounded-l-md"
        />
        <button className="bg-gray-300 p-2 rounded-r-md">검색</button>
      </div>
      <div className="space-y-4 overflow-y-auto" style={{ maxHeight: '60vh' }}>
        {episodeList.map((episode) => (
          <div
            key={episode.id}
            className="flex items-center p-4 bg-white border border-gray-300 rounded shadow-sm"
          >
            <img
              src={episode.image}
              alt="Episode Thumbnail"
              className="w-16 h-16 mr-4 rounded"
            />
            <div className="flex-grow">
              <h4 className="font-bold">{episode.title}</h4>
              <p className="text-gray-500">{episode.description}</p>
              <p className="text-gray-400 text-sm">{episode.date}</p>
            </div>
            <button
              className="ml-4 bg-blue-700 text-white py-2 px-4 rounded"
              onClick={() => handleEpisodeDetail(episode.id)}
            >
              상세 보기
            </button>
          </div>
        ))}
      </div>
      <button
        className="mt-4 bg-blue-700 text-white py-2 px-4 rounded-full flex items-center"
        onClick={handleEpisodeCreate}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>
        에피소드 추가
      </button>
    </div>
  )
}

export default EpisodeList
