import React, { useState } from 'react'
import axios from 'axios'

const UploadForm = () => {
  const [image, setImage] = useState(null)
  const [audio, setAudio] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [age, setAge] = useState(true)
  const [upload, setUpload] = useState(0)

  const handleImageChange = (e) => {
    setImage(e.target.files[0])
  }

  const handleAudioChange = (e) => {
    setAudio(e.target.files[0])
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleAgeChange = (e) => {
    setAge(e.target.checked)
  }

  const handleUploadChange = (e) => {
    setUpload(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const episodeData = {
      title: title,
      description: description,
      age: age.toString(), // JSON 문자열로 변환 시 문자열로 설정
      upload: parseInt(upload),
    }

    const formData = new FormData()
    formData.append('image', image)
    formData.append('audio', audio)
    formData.append('episodeData', JSON.stringify(episodeData))

    console.log('1 : ' + episodeData)
    console.log(formData.get('image')) // 디버깅을 위해 추가
    console.log(formData.get('audio')) // 디버깅을 위해 추가
    console.log(formData.get('episodeData')) // 디버깅을 위해 추가

    try {
      const response = await axios.post(
        'http://localhost:8080/episode',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        },
      )
      console.log(response.data)
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Image:</label>
        <input type="file" onChange={handleImageChange} accept="image/*" />
      </div>
      <div>
        <label>Audio:</label>
        <input type="file" onChange={handleAudioChange} accept="audio/*" />
      </div>
      <div>
        <label>Title:</label>
        <input type="text" value={title} onChange={handleTitleChange} />
      </div>
      <div>
        <label>Description:</label>
        <input
          type="text"
          value={description}
          onChange={handleDescriptionChange}
        />
      </div>
      <div>
        <label>Age:</label>
        <input type="checkbox" checked={age} onChange={handleAgeChange} />
      </div>
      <div>
        <label>Upload:</label>
        <input type="number" value={upload} onChange={handleUploadChange} />
      </div>
      <button type="submit">Upload Episode</button>
    </form>
  )
}

export default UploadForm
