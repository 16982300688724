import React, { useEffect, useState } from 'react'
// import { useSpring, animated } from 'react-spring'
import { Copy } from '../../asset/icon'
import axios from 'axios'
// import { useNavigate } from 'react-router-dom'

const CardComponent = () => {
  const ip = process.env.REACT_APP_URL
  const [profile, setProfile] = useState([])
  // const [isOpen, setIsOpen] = useState(false)
  // const props = useSpring({
  //   height: isOpen ? '200px' : '50px',
  //   opacity: isOpen ? 1 : 0,
  //   config: { tension: 250, friction: 20 },
  // })

  useEffect(() => {
    handleProfile()
  })

  const handleProfile = async () => {
    try {
      await axios
        .get(`${ip}/channel`, {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          setProfile(response.data)
        })
    } catch (error) {
      console.error('There was an error uploading the files!', error)
    }
  }

  return (
    <div>
      <header className="flex justify-end items-center mb-5 w-full">
        <div className="flex items-center bg-white p-4 rounded-lg shadow w-full">
          <img
            src={profile.channelImage}
            alt="Profile"
            className="w-12 h-12 rounded-full mr-2"
          />
          <div className="flex flex-col items-start">
            <div className="font-bold text-lg">{profile.channelTitle}</div>
            {/*<div className="text-blue-700 text-sm no-underline hover:underline cursor-pointer">*/}
            {/*  <p onClick={modify}>프로필 편집하기</p>*/}
            {/*</div>*/}
            <div className="text-blue-700 text-sm no-underline hover:underline cursor-pointer">
              <div className="flex">
                <p>피드 복사하기</p>
                <img src={Copy} alt="Copy" />
              </div>
            </div>
          </div>
        </div>
      </header>
      {/*<section className="p-5 rounded-lg overflow-y-auto flex-grow relative">*/}
      {/*  <div className="w-full p-5">*/}
      {/*    <div className="bg-white rounded-lg shadow-lg p-4 mb-2">*/}
      {/*      <div className="flex justify-between items-center">*/}
      {/*        <div className="flex items-center">*/}
      {/*          <img*/}
      {/*            src="http://via.placeholder.com/50"*/}
      {/*            alt="Profile"*/}
      {/*            className="w-12 h-12 rounded-full mr-2"*/}
      {/*          />*/}
      {/*          <div>*/}
      {/*            <h3 className="font-bold">세경이의 하루아채</h3>*/}
      {/*            <p className="text-gray-600">*/}
      {/*              세경이의 하루아채는 야채에 관한 다양한 소식들...*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="text-blue-700 text-sm font-bold">*/}
      {/*          2024/06/04 18:00*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <animated.div style={props}>*/}
      {/*      {isOpen && (*/}
      {/*        <div>*/}
      {/*          {[...Array(5)].map((_, index) => (*/}
      {/*            <div*/}
      {/*              key={index}*/}
      {/*              className="bg-white rounded-lg shadow-lg p-4 mb-2"*/}
      {/*            >*/}
      {/*              <div className="flex justify-between items-center">*/}
      {/*                <div className="flex items-center">*/}
      {/*                  <img*/}
      {/*                    src="http://via.placeholder.com/50"*/}
      {/*                    alt="Profile"*/}
      {/*                    className="w-12 h-12 rounded-full mr-2"*/}
      {/*                  />*/}
      {/*                  <div>*/}
      {/*                    <h3 className="font-bold">세경이의 하루아채</h3>*/}
      {/*                    <p className="text-gray-600">*/}
      {/*                      세경이의 하루아채는 야채에 관한 다양한 소식들...*/}
      {/*                    </p>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*                <div className="text-blue-700 text-sm font-bold">*/}
      {/*                  2024/06/04 18:00*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          ))}*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </animated.div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<div className="flex justify-center mt-4">*/}
      {/*  <button*/}
      {/*    onClick={() => setIsOpen(!isOpen)}*/}
      {/*    className="focus:outline-none"*/}
      {/*  >*/}
      {/*    <svg*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*      className="h-6 w-6"*/}
      {/*      fill="none"*/}
      {/*      viewBox="0 0 24 24"*/}
      {/*      stroke="currentColor"*/}
      {/*    >*/}
      {/*      <path*/}
      {/*        strokeLinecap="round"*/}
      {/*        strokeLinejoin="round"*/}
      {/*        strokeWidth={2}*/}
      {/*        d="M19 9l-7 7-7-7"*/}
      {/*      />*/}
      {/*    </svg>*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  )
}

export default CardComponent
