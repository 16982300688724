import React from 'react'

const Footer = () => {
  return (
    <footer className="py-8 bg-gray-800 text-white">
      <div className="max-w-5xl mx-auto px-4">
        <div className="flex justify-between items-center">
          <div>
            <p className="h-8 mb-4">아이엠</p>
            <p>아이엠 | 대표이사: OOO | 사업자등록번호: 000-00-00000</p>
            <p>통신판매업신고: 제0000-0000호</p>
            <p>서울시 강남구 테헤란로 00길 00, 0층</p>
          </div>
          <div>
            <p>고객센터: 02-0000-0000</p>
            <p>운영시간: 평일 09:00 - 18:00 (점심시간 13:00 - 14:00)</p>
            <p>이메일: austin252@naver.com</p>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>COPYRIGHT 2023 IM COMPANY. ALL RIGHTS RESERVED.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
