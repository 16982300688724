import React from 'react'
import OutLineSideBar from '../../newComponent/outline/OutLineSideBar'
import AdListComponent from '../../newComponent/advertise/AdListComponent'
import CardComponent from '../../newComponent/mypage/CardComponent'

const NewAdList = () => {
  return (
    <div className="flex h-screen bg-blue-700">
      <OutLineSideBar />
      <main className="flex-grow flex justify-center items-center p-5 ml-[-5%]">
        <div
          className="bg-[#F7F8FC] p-5 rounded-2xl flex h-[90%] relative shadow-md overflow-y-auto"
          style={{ flexBasis: '90%' }}
        >
          <div className="basis-2/3 h-full overflow-y-auto">
            <AdListComponent />
          </div>
          <div className="w-[2px] bg-gray-300 mx-5"></div>
          <div className="basis-1/3 flex flex-col">
            <CardComponent />
          </div>
        </div>
      </main>
    </div>
  )
}

export default NewAdList
