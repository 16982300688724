import React from 'react'
import useMyPage from '../../hooks/useMyPage'

const MyPageComponent = () => {
  const {
    handleImageUpload,
    modify,
    profile,
    categoryList = [],
    categorySubList = [],
    activeIndex,
    activeSubIndex,
    fileInputRef,
  } = useMyPage()

  return (
    <div className="w-full p-5 space-y-6">
      <h2 className="text-xl font-bold mb-4">마이페이지</h2>

      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">에피소드 이미지</label>
        <div className="flex items-center">
          {profile.channelImage ? (
            <div className="mr-4">
              <img
                src={profile.channelImage}
                alt="Episode"
                className="w-24 h-24 object-cover rounded"
              />
            </div>
          ) : (
            <div className="mr-4">
              <div className="w-24 h-24 border-dashed border-2 border-gray-300 flex items-center justify-center rounded">
                <span className="text-gray-500">미리보기</span>
              </div>
            </div>
          )}
          <div>
            {!profile.channelImage && (
              <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded mb-2">
                {/*<button*/}
                {/*  onClick={handleButtonClick}*/}
                {/*  className="bg-blue-700 text-white py-2 px-4 rounded mb-2"*/}
                {/*>*/}
                {/*  + 사진첨부*/}
                {/*</button>*/}
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">사용자 이름</label>
        <p className="w-full p-2 border rounded">{profile.username}</p>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">팟캐스트 이름</label>
        <p className="w-full p-2 border rounded">{profile.channelTitle}</p>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">필수 정보 설명</label>
        <p className="w-full p-2 border rounded">
          {profile.channelDescription}
        </p>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">메인 카테고리 선택</label>
        <div className="flex space-x-4">
          {categoryList.map((item) => {
            const buttonClass = activeIndex.includes(item.name)
              ? 'bg-blue-500 text-white py-2 px-4 rounded'
              : 'bg-gray-300 text-gray-700 py-2 px-4 rounded'

            return (
              <div
                key={item.id}
                className={buttonClass}
                // onClick={() => handleButtonClick(index, item.name)}
              >
                {item.name}
              </div>
            )
          })}
        </div>

        <label className="block text-gray-700 mb-2">서브 카테고리 선택</label>
        <div className="flex space-x-4">
          {categorySubList.map((item) => {
            const buttonClass = activeSubIndex.includes(item.name)
              ? 'bg-blue-500 text-white py-2 px-4 rounded'
              : 'bg-gray-300 text-gray-700 py-2 px-4 rounded'

            return (
              <div
                key={item.id}
                className={buttonClass}
                // onClick={() => handleSubButtonClick(index, item.name)}
              >
                {item.name}
              </div>
            )
          })}
        </div>
      </div>

      <div className="p-4 bg-white border border-gray-300 rounded">
        <label className="block text-gray-700 mb-2">연령제한 컨텐츠</label>
        <div className="flex items-center">
          <input
            type="checkbox"
            className="mr-2 pointer-events-none"
            checked={profile.ageLimit}
            readOnly
          />
          <span className="text-gray-700">연령제한이 포함된 컨텐츠입니다.</span>
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <button
          onClick={modify}
          className="bg-blue-700 text-white py-2 px-4 rounded"
        >
          수정
        </button>
      </div>
    </div>
  )
}

export default MyPageComponent
