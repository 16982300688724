import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

const useEpisodeCreateForm_v2 = () => {
  const location = useLocation()
  const audioFile = location.state?.audioFile
  const imageData = location.state?.image
  const navigate = useNavigate()
  const [preAudio, setPreAudio] = useState(null)
  const [audio, setAudio] = useState(null)
  const [playbackRate, setPlaybackRate] = useState(1.0)
  const waveformRef = useRef(null)
  const audioInputRef = useRef(null)
  const wavesurferRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [adIndex, setAdIndex] = useState([])

  useEffect(() => {
    if (audioFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreAudio(reader.result)
      }
      reader.readAsDataURL(audioFile)
      setAudio(audioFile)
    }
  }, [])

  useEffect(() => {
    if (preAudio && waveformRef.current) {
      const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#0d6efd',
        progressColor: '#0d6efd',
        height: 100,
        responsive: true,
        barWidth: 2,
        barHeight: 1,
        barGap: 2,
        plugins: [RegionsPlugin.create()], // RegionsPlugin 추가
      })

      wavesurferRef.current = wavesurfer

      const updateCurrentTime = () => {
        setCurrentTime(wavesurfer.getCurrentTime())
      }

      wavesurfer.on('audioprocess', updateCurrentTime)
      wavesurfer.on('seek', updateCurrentTime)

      wavesurfer.load(preAudio)
      wavesurfer.setPlaybackRate(playbackRate)

      return () => wavesurfer.destroy()
    }
  }, [preAudio])

  const handleAudioUpload = (event) => {
    const file = event.target.files[0]
    setAudio(event.target.files[0])
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        // const base64String = reader.result.split(',')[1]
        // const fileData = {
        //   base64: base64String,
        //   name: file.name,
        //   type: file.type,
        //   size: file.size,
        // }
        setPreAudio(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    if (audioInputRef.current) {
      audioInputRef.current.click()
    }
  }

  const handleAudioRemove = () => {
    setPreAudio(null)
    if (audioInputRef.current) {
      audioInputRef.current.value = null
    }
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy()
      wavesurferRef.current = null
    }
    setPreAudio(null)
  }

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause()
    }
  }

  const handleSkipBackward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime - 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handleSkipForward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime + 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handlePlaybackRateChange = (rate) => {
    if (wavesurferRef.current) {
      wavesurferRef.current.setPlaybackRate(rate)
      setPlaybackRate(rate)
    }
  }

  const handleBefore = () => {
    navigate('/NewEpisodeCreate_v1', { state: { audio, imageData } })
  }

  const handleSave = () => {
    navigate('/NewEpisodeCreate_v3', { state: { audio, imageData } })
  }

  // if문이 null이 뜸 -> 이거 해결하면 수정, 삭제 가능한데 모르겠음.
  const getRegionById = (id) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (regionsPlugin && regionsPlugin.regions && regionsPlugin.regions.list) {
      return Object.values(regionsPlugin.regions.list).find(
        (region) => region.id === id,
      )
    }
    return null
  }

  const handleAddRegion = (startTime, content, index) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (!regionsPlugin) {
      console.error('RegionsPlugin is not active')
      return
    }

    const existingRegion = getRegionById(index)

    if (existingRegion) {
      console.log('include')
      existingRegion.update({
        start: startTime,
        content: content,
        color: '#000000',
      })
    } else {
      console.log('not include')
      setAdIndex([...adIndex, index])
      regionsPlugin.addRegion({
        id: index,
        start: startTime,
        content: content,
        color: '#000000',
      })
    }
  }

  const handleRemoveRegion = (index) => {
    const regionsPlugin = wavesurferRef.current
      .getActivePlugins()
      .find((plugin) => plugin instanceof RegionsPlugin)
    if (!regionsPlugin) {
      console.error('RegionsPlugin is not active')
      return
    }
    console.log('remove be')
    const regionToRemove = getRegionById(index)
    if (regionToRemove) {
      console.log('remove suc')
      regionToRemove.remove()
      setAdIndex(adIndex.filter((id) => id !== index))
    }
  }

  return {
    handleAudioUpload,
    handleButtonClick,
    handleAudioRemove,
    handlePlayPause,
    handleSkipForward,
    handleSkipBackward,
    handlePlaybackRateChange,
    handleSave,
    handleBefore,
    handleAddRegion,
    handleRemoveRegion,
    currentTime,
    preAudio,
    playbackRate,
    waveformRef,
    audioInputRef,
  }
}

export default useEpisodeCreateForm_v2
