import React from 'react'
import { useNavigate } from 'react-router-dom'

const AdListComponent = () => {
  const navigate = useNavigate()

  const adMore = () => {
    navigate('/NewAdMore')
  }

  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">광고</h2>
      <section>
        <div className="p-4 bg-white border border-gray-300 rounded">
          <h2 className="text-lg font-bold mb-4">저장된 광고</h2>
          <div className="flex items-center mb-6">
            <div className="mr-4">
              <img
                src="https://via.placeholder.com/100"
                alt="Ad1"
                className="rounded-full"
              />
              <p className="text-center mt-2">삼성</p>
            </div>
            <div className="mr-4">
              <img
                src="https://via.placeholder.com/100"
                alt="Ad2"
                className="rounded-full"
              />
              <p className="text-center mt-2">쿠팡</p>
            </div>
            <button className="bg-gray-300 text-gray-700 py-2 px-4 rounded-full">
              +
            </button>
          </div>
        </div>
        <div className="p-4 bg-white border border-gray-300 rounded">
          <h2 className="text-lg font-bold mb-4">xxx 광고</h2>
          <div className="flex items-center mb-6">
            <div className="mr-4" onClick={adMore}>
              <img
                src="https://via.placeholder.com/100"
                alt="Ad1"
                className="rounded-full hover:cursor-pointer"
              />
              <p className="text-center mt-2 hover:cursor-pointer">
                배달의 민족
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AdListComponent
