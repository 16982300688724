import React from 'react'
import useEpisodeCreateForm_v1 from '../../hooks/useEpisodeCreateForm_v1'

const EpisodeCreateForm_v1 = () => {
  const {
    handleImageUpload,
    handleButtonClick,
    handleImageRemove,
    handleSave,
    preImage,
    title,
    setTitle,
    description,
    setDescription,
    fileInputRef,
  } = useEpisodeCreateForm_v1()

  return (
    <div className="w-full p-5">
      <h2 className="text-xl font-bold mb-4">에피소드 만들기</h2>
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-blue-700 font-bold">에피소드</div>
          <div className="flex items-center space-x-2">
            <div className="h-2 w-2 bg-blue-700 rounded-full"></div>
            <div className="h-2 w-2 bg-gray-300 rounded-full"></div>
            <div className="h-2 w-2 bg-gray-300 rounded-full"></div>
          </div>
        </div>
        <div className="bg-gray-200 h-1 w-full rounded-full">
          <div
            className="bg-blue-700 h-1 rounded-full"
            style={{ width: '33%' }}
          ></div>
        </div>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">에피소드 제목</label>
        <input
          type="text"
          placeholder="(예) 비하인드 영화 vol.2 - 에피소드 만들기"
          className="w-full p-2 border rounded"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">에피소드 이미지</label>
        <div className="flex items-center">
          {preImage ? (
            <div className="mr-4">
              <img
                src={preImage}
                alt="Episode"
                className="w-24 h-24 object-cover rounded"
              />
            </div>
          ) : (
            <div className="mr-4">
              <div className="w-24 h-24 border-dashed border-2 border-gray-300 flex items-center justify-center rounded">
                <span className="text-gray-500">미리보기</span>
              </div>
            </div>
          )}
          <div>
            {!preImage && (
              <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded mb-2">
                <button
                  onClick={handleButtonClick}
                  className="bg-blue-700 text-white py-2 px-4 rounded mb-2"
                >
                  + 사진첨부
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </div>
            )}
            {preImage && (
              <button
                onClick={handleImageRemove}
                className="bg-red-700 text-white py-2 px-4 rounded"
              >
                X 사진삭제
              </button>
            )}
          </div>
        </div>
        <p className="text-gray-500 text-sm mt-2">
          1,400 ~ 3,000 픽셀의 정사각형 사진을 첨부해주세요. (jpg, png)
        </p>
      </div>
      <div className="p-4 bg-white border border-gray-300 rounded mb-4">
        <label className="block text-gray-700 mb-2">에피소드 설명</label>
        <textarea
          placeholder="에피소드 설명을 입력하세요"
          className="w-full p-2 border rounded resize-none"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleSave}
          className="bg-blue-700 text-white py-2 px-4 rounded ml-auto"
        >
          다음
        </button>
      </div>
    </div>
  )
}

export default EpisodeCreateForm_v1
