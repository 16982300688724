import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import React from 'react'

//
import LineChart from './chart/LineChart'
import BarChart from './chart/BarChart'
import PieChart from './chart/PieChart'
import UploadForm from './board/UploadForm'

//new
import MainPage from './newViews/mainpage/MainPage'
import NewMyPage from './newViews/mypage/MyPage'
import NewEpisodeManagement from './newViews/episodemanage/NewEpisodeManagement'
import NewEpisodeManagementDetail from './newViews/episodemanage/NewEpisodeManagementDetail'
import NewEpisodeCreate_v1 from './newViews/episodecreate/EpisodeCreate_v1'
import NewEpisodeCreate_v2 from './newViews/episodecreate/EpisodeCreate_v2'
import NewEpisodeCreate_v3 from './newViews/episodecreate/EpisodeCreate_v3'
import NewAdList from './newViews/advertise/AdList'
import NewAdDetail from './newViews/advertise/AdDetail'
import NewAdMore from './newViews/advertise/AdMore'
import ModifyMyPage from './newViews/modifyMyPage/ModifyMyPage'
import Login from './newViews/login/Login'
import ApplePlatform from './newViews/platform/ApplePlatform'
import Link from './newViews/link/Link'

import RssFeed from './newViews/feed/UpdateChannelInfo'

import { atom } from 'recoil'
// import PrivateRoute from './auth/PrivateRoute'

export const audioChunksState = atom({
  key: 'audioChunksState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
})

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*chart*/}
        <Route path="/LineChart" element={<LineChart />} />
        <Route path="/BarChart" element={<BarChart />} />
        <Route path="/PieChart" element={<PieChart />} />
        <Route path="/UploadForm" element={<UploadForm />} />

        {/*new*/}
        <Route path="/Login" element={<Login />} />
        <Route path="/" element={<MainPage />} />

        {/*<Route element={<PrivateRoute />}>*/}
        <Route path="/NewMyPage" element={<NewMyPage />} />
        <Route path="/ModifyMyPage" element={<ModifyMyPage />} />
        <Route
          path="/NewEpisodeManagement"
          element={<NewEpisodeManagement />}
        />
        <Route
          path="/NewEpisodeManagement/:id"
          element={<NewEpisodeManagementDetail />}
        />
        <Route path="/NewEpisodeCreate_v1" element={<NewEpisodeCreate_v1 />} />
        <Route path="/NewEpisodeCreate_v2" element={<NewEpisodeCreate_v2 />} />
        <Route path="/NewEpisodeCreate_v3" element={<NewEpisodeCreate_v3 />} />

        <Route path="/Link" element={<Link />} />
        <Route path="/ApplePlatform" element={<ApplePlatform />} />
        <Route path="/NewAdList" element={<NewAdList />} />
        <Route path="/NewAdDetail" element={<NewAdDetail />} />
        <Route path="/NewAdMore" element={<NewAdMore />} />
        <Route path="/RssFeed" element={<RssFeed />} />
        {/*</Route>*/}
      </Routes>
    </BrowserRouter>
  )
}

export default App
