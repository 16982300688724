import React from 'react'
import OutLineSideBar from '../../newComponent/outline/OutLineSideBar'
import MyPageComponent from '../../newComponent/mypage/MyPageComponent'
import CardComponent from '../../newComponent/mypage/CardComponent'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

const MyPage = () => {
  const navigate = useNavigate()

  const Logout = () => {
    const allCookies = Cookies.get()
    for (let cookieName in allCookies) {
      Cookies.remove(cookieName)
    }
    navigate('/')
  }

  return (
    <div className="flex h-screen bg-blue-700">
      <OutLineSideBar />
      <main className="flex-grow flex justify-center items-center p-5 ml-[-5%]">
        <div
          className="bg-[#F7F8FC] p-5 rounded-2xl flex h-[90%] relative shadow-md overflow-y-auto"
          style={{ flexBasis: '90%' }}
        >
          <div className="basis-2/3 h-full overflow-y-auto">
            <MyPageComponent />
          </div>
          <div className="w-[2px] bg-gray-300 mx-5"></div>
          <div className="basis-1/3 flex flex-col">
            <div className="flex justify-end border-blue-400">
              <button
                onClick={Logout}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                로그아웃
              </button>
            </div>
            <CardComponent />
          </div>
        </div>
      </main>
    </div>
  )
}

export default MyPage
