import React, { useState, useRef, useEffect } from 'react'
import WaveSurfer from 'wavesurfer.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

// eslint-disable-next-line react/prop-types
const AudioPlayer = ({ audioFile }) => {
  const [audio, setAudio] = useState(null)
  const [playbackRate, setPlaybackRate] = useState(1.0)
  const [currentTime, setCurrentTime] = useState(0)
  const waveformRef = useRef(null)
  const audioInputRef = useRef(null)
  const wavesurferRef = useRef(null)

  const initWaveSurfer = () => {
    if (waveformRef.current && !wavesurferRef.current) {
      const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#0d6efd',
        progressColor: '#0d6efd',
        height: 100,
        responsive: true,
        barWidth: 2,
        barHeight: 1,
        barGap: 2,
        plugins: [RegionsPlugin.create()], // RegionsPlugin 추가
      })

      wavesurferRef.current = wavesurfer

      const updateCurrentTime = () => {
        setCurrentTime(wavesurfer.getCurrentTime())
      }

      wavesurfer.on('audioprocess', updateCurrentTime)
      wavesurfer.on('seek', updateCurrentTime)

      wavesurfer.load(audioFile)
      wavesurfer.setPlaybackRate(playbackRate)

      return () => wavesurfer.destroy()
    }
  }

  useEffect(() => {
    initWaveSurfer()

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy()
        wavesurferRef.current = null
      }
    }
  }, [audioFile, audio, playbackRate])

  const handleAudioUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setAudio(reader.result)
        sessionStorage.setItem('episodeAudio', reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleButtonClick = () => {
    if (audioInputRef.current) {
      audioInputRef.current.click()
    }
  }

  const handleAudioRemove = () => {
    setAudio(null)
    if (audioInputRef.current) {
      audioInputRef.current.value = null
    }
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy()
      wavesurferRef.current = null
    }
    sessionStorage.removeItem('episodeAudio')
  }

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause()
    }
  }

  const handleSkipBackward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime - 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handleSkipForward = () => {
    if (wavesurferRef.current) {
      const currentTime = wavesurferRef.current.getCurrentTime()
      wavesurferRef.current.seekTo(
        (currentTime + 3) / wavesurferRef.current.getDuration(),
      )
    }
  }

  const handlePlaybackRateChange = (rate) => {
    if (wavesurferRef.current) {
      wavesurferRef.current.setPlaybackRate(rate)
      setPlaybackRate(rate)
      sessionStorage.setItem('playbackRate', rate)
    }
  }

  return (
    <div className="p-4 bg-white border border-gray-300 rounded mb-4">
      <label className="block text-gray-700 mb-2">오디오 파일</label>
      {audio || audioFile ? (
        <div>
          <div ref={waveformRef} className="w-full mb-2"></div>
          <div className="text-center mb-2">
            <span className="text-gray-700">
              현재 재생 시간: {currentTime.toFixed(2)}
            </span>
          </div>
          <div className="flex items-center space-x-2 justify-center mb-2">
            <button
              onClick={handleSkipBackward}
              className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
            >
              3초 뒤로
            </button>
            <button
              onClick={handlePlayPause}
              className="bg-blue-700 text-white py-1 px-3 rounded"
            >
              재생/정지
            </button>
            <button
              onClick={handleSkipForward}
              className="bg-gray-200 text-gray-700 py-1 px-3 rounded"
            >
              3초 앞으로
            </button>
          </div>
          <div className="flex items-center space-x-2 justify-center">
            <label className="text-gray-700">재생 속도: </label>
            <select
              value={playbackRate}
              onChange={(e) =>
                handlePlaybackRateChange(parseFloat(e.target.value))
              }
              className="border rounded p-1"
            >
              <option value={0.5}>0.5x</option>
              <option value={1.0}>1.0x</option>
              <option value={1.5}>1.5x</option>
              <option value={2.0}>2.0x</option>
            </select>
          </div>
          <button
            onClick={handleAudioRemove}
            className="mt-2 bg-red-700 text-white py-2 px-4 rounded"
          >
            X 오디오 삭제
          </button>
        </div>
      ) : (
        <div className="border-dashed border-2 border-gray-300 p-4 text-center rounded">
          <button
            onClick={handleButtonClick}
            className="bg-blue-700 text-white py-2 px-4 rounded mb-2"
          >
            + 오디오 선택
          </button>
          <input
            type="file"
            accept="audio/*"
            ref={audioInputRef}
            onChange={handleAudioUpload}
            className="hidden"
          />
        </div>
      )}
    </div>
  )
}

export default AudioPlayer
